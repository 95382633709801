export const direction = [
  {'value': 'E', 'display': 'E'},
  {'value': 'W', 'display': 'W'},
  {'value': 'S', 'display': 'S'},
  {'value': 'N', 'display': 'N'}
]


export const limits = [
  5, 10, 15, 20, 50, 100
];


export const county = [
  {'value': '', 'display': ''}
];


export const roadway = [
  {'value': '', 'display': ''}
];
