import React, {  } from 'react';
import { AppBar, Toolbar, Typography, LinearProgress, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { IncidentSpecific } from '../../../Models/Incident';
import { GET_INCIDENT_RESTORE } from '../../../Redux/Actions';
import { AppDispatch } from '../../../store';

type StatusBarProps = {
  incident: IncidentSpecific
  dispatch: AppDispatch
};

const StatusBar: React.FC<StatusBarProps> = ({ incident, dispatch }) => {
  const [open, setOpen] = React.useState(false);

  const header = (() => {
    switch (incident.videosAvailability) {
      case 'notRestored':
        return 'Data needs to be restored';
      case 'restoring':
        return 'Data is currently being restored';
      case 'available':
        return 'Data is available for access';
      default:
        return '';
    }
  })();

  const subtext = (() => {
    switch (incident.videosAvailability) {
      case 'notRestored':
        return 'Data is not available for access. Please restore the data to access it. This process may take between 1-12 hours.';
      case 'restoring':
        return `Please wait until the data is available. This process takes between 1-12 hours. The data will be available for access in less than ${calculateTimeLeft(incident.videosRestorationInitialized)}.`;
      case 'available':
        return `The data will be available for access until ${incident.videosRestorationExpiryDate}.`;
      default:
        return '';
    }
  })();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRestore = () => {
    dispatch({ type: GET_INCIDENT_RESTORE, payload: null });
    setOpen(false);
  }

  return (
    <>
    {header !== '' && (
      <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirm Restoration of Data"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to restore the data? This process may take between 1-12 hours. The data will than be available for access for 7 days.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={handleRestore}>Yes</Button>
        </DialogActions>
      </Dialog>
      <AppBar position="static" color={incident.videosAvailability === 'available' ? 'success' : 'primary'}>
        <Toolbar>
          <div style={{ flex: 1, marginTop: "5px", marginBottom: "10px" }}>
            <Typography variant="h6">{header}</Typography>
            <Typography variant="subtitle1">{subtext}</Typography>
            {incident.videosAvailability === 'restoring' && (
              <>
                <LinearProgress variant="determinate" value={calculateTimePercentage(incident.videosRestorationInitialized)} color="secondary" style={{ marginTop: "10px" }} />
              </>
            )}
            {incident.videosAvailability === 'notRestored' && (
              <>
                <Button variant="contained" color="secondary" style={{ marginTop: "10px" }} onClick={handleClickOpen} > Restore </Button>
              </>
            )}
          </div>
        </Toolbar>
      </AppBar>
    </>
    )}
    </>
  );
};

export default StatusBar;


function calculateTimePercentage(inputDate: string) {
  const startDate = new Date(inputDate);

  const now = new Date();
  const nowUTC = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds()));

  const twelveHoursInMs = 12 * 60 * 60 * 1000;
  const timePassedInMs = nowUTC.getTime() - startDate.getTime();
  const percentage = (timePassedInMs / twelveHoursInMs) * 100;
  return Math.min(Math.max(percentage, 0), 100);
}

function calculateTimeLeft(inputDate: string): string {
  const startDate = new Date(inputDate);

  const now = new Date();
  const nowUTC = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds()));
  console.log(now, nowUTC)

  const twelveHoursInMs = 12 * 60 * 60 * 1000;
  const elapsedTimeInMs = nowUTC.getTime() - startDate.getTime();
  const timeLeftInMs = twelveHoursInMs - elapsedTimeInMs;

  if (timeLeftInMs <= 0) {
    return "0 hours 0 minutes";
  }

  const hoursLeft = Math.floor(timeLeftInMs / (60 * 60 * 1000));
  const minutesLeft = Math.floor((timeLeftInMs % (60 * 60 * 1000)) / (60 * 1000));

  return `${hoursLeft} hours ${minutesLeft} minutes`;
}
